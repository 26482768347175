import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Logo1 from '../../source/logo/logo1.svg';
// import Facebook from '../../source/svg/facebook.svg';
// import Instagram from '../../source/svg/instagram.svg';
// import Linkedin from '../../source/svg/linkedin.svg';
import MenuBurger from '../../source/svg/burger.svg'
const Nav = () => {

    const [page, setPage] = useState(1);
    const [scrollPosition, setScrollPosition] = useState(0)
    const openMenu = () =>{
        const menuBurger = document.getElementById('nav-burger');
        menuBurger.style.animation = '3s openBurger';
        menuBurger.style.display= 'flex'
    }
    const closedMenu = () =>{
        const menuBurger = document.getElementById('nav-burger');
        menuBurger.style.animation = '1s closedBurger ';
        setTimeout(() => {
            menuBurger.style.display= 'none'
        }, 3000);
    }
    window.addEventListener('scroll', e =>{
        setScrollPosition(window.scrollY) 
    })
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [page])
    
  
    
    
    

    return ( 
        <>
            <nav className='nav-container' style={scrollPosition >=1 ? null:{boxShadow:'none'}}>
                <img src={Logo1} alt='Logo 1' className='nav-logo'/>
                
                <div className='nav-router'>
                    <div onClick={() => setPage(1)} className='link'>
                        <Link to="/" style={page === 1 ? {color:'var(--color1)'}:null}>HOME</Link>
                    </div>
                    <div onClick={() => setPage(2)} className='link'>
                        <Link to="/solutions" style={page === 2 ? {color:'var(--color1)'}:null}>OVERVIEW</Link>
                    </div>
                    {/* <div onClick={() => setPage(3)} className='link'>
                        <Link to="/technologies" style={page === 3 ? {color:'var(--color1)'}:null}>TECHNOLOGIES</Link>
                    </div> */}
                    <div onClick={() => setPage(4)} className='link'>
                        <Link to="/carbon-offset" style={page === 4 ? {color:'var(--color1)'}:null}>CARBON CAPTURE</Link>
                    </div>
                    <div onClick={() => setPage(5)} className='link'>
                        <Link to="/about" style={page === 5 ? {color:'var(--color1)'}:null}>ABOUT 180</Link>
                    </div>
                    <div onClick={() => {setPage(6);closedMenu()}} className='link'>
                        <Link to="/contact" style={page === 6 ? {color:'var(--color1)'}:null}>CONTACT US</Link>
                    </div>
                    {/* <div className='nav-social'>
                        <a href='/'><img src={Facebook} alt='Facebook icon'/></a>
                        <a href='/'><img src={Instagram} alt='Instagram icon'/></a>
                        <a href='/'><img src={Linkedin} alt='Linkedin icon'/></a>
                    </div> */}
                </div>
                <img src={MenuBurger} alt='Menu Burger' className='icon-burger' onClick={() => openMenu() }/>
                <div className='nav-burger' id='nav-burger' display='none'>
                    <div onClick={() => {setPage(1);closedMenu()}} className='link'>
                        <Link to="/" style={page === 1 ? {color:'var(--color2)'}:null}>HOME</Link>
                    </div>
                    <div onClick={() => {setPage(2);closedMenu()}} className='link'>
                        <Link to="/solutions" style={page === 2 ? {color:'var(--color2)'}:null}>OVERVIEW</Link>
                    </div>
                    {/* <div onClick={() => {setPage(3);closedMenu()}} className='link'>
                        <Link to="/technologies" style={page === 3 ? {color:'var(--color2)'}:null}>TECHNOLOGIES</Link>
                    </div> */}
                    <div onClick={() => {setPage(4);closedMenu()}} className='link'>
                        <Link to="/carbon-offset" style={page === 4 ? {color:'var(--color2)'}:null}>CARBON CAPTURE</Link>
                    </div>
                    <div onClick={() => {setPage(5);closedMenu()}} className='link'>
                        <Link to="/about" style={page === 5 ? {color:'var(--color2)'}:null}>ABOUT 180</Link>
                    </div>
                    <div onClick={() => {setPage(6);closedMenu()}} className='link'>
                        <Link to="/contact" style={page === 6 ? {color:'var(--color2)'}:null}>CONTACT US</Link>
                    </div>
                    {/* <div className='nav-social'>
                        <a href='/'><img src={Facebook} alt='Facebook icon'/></a>
                        <a href='/'><img src={Instagram} alt='Instagram icon'/></a>
                        <a href='/'><img src={Linkedin} alt='Linkedin icon'/></a>
                    </div> */}
                </div>

            </nav>
        </>
    );
}
 
export default Nav;