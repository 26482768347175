import React from 'react';
import Icon1 from '../../../source/svg/cookstoveH3.svg';
import Icon2 from '../../../source/svg/treesH3.svg';
import Icon3 from '../../../source/svg/handH3.svg';

const HomeSection3 = () => {
    return ( 
        <div className='home3-container'>
            <h3>Our solutions stop deforestation and enable reforestation.</h3>
            <div className='home3-icons'>
                <div>
                    <img src={Icon1} alt='icon'/>
                    <h4>Efficient <br/> Cookstoves</h4>
                    <p>
                        180 cookstoves burn 67% less wood-fuel - reducing the need for illegal deforesation! <br/> <br/> Bluetooth sensors accurately record data for a more effective solution and extremely reliable carbon-capture.                    </p>
                </div>
                
                <div>
                    <img src={Icon3} alt='icon'/>
                    <h4>Regeneration <br/> of forests</h4>
                    <p>
                        a{')'} Wild Forests are regenerated and protected on a nationwide scale. Land is assigned to 180 by Governments.
                        <br/> <br/>
                        b{')'} Simultaneously, 180 Community Forests plant fast-growing, sustainable, non-invasive species, and are the principal source of sustainable biofuel and build materials for communities.
                    </p>
                </div>
            </div>
            {/* <div>
                    <img src={Icon2} alt='icon'/>
                    <h4>High impact on carbon reduction</h4>
                    <p>
                        By planting fast growing trees on farms we provide firewood for cooking and timber for construction and making.
                    </p>
                </div> */}
            <h3>
                180 delivers significant carbon reduction. With innovative and non-invasive technologies, such as satellite-imagery, geo-tagging, and cookstove sensors, reliable, certified carbon credits create a measurable impact on climate change.
            </h3>
        </div>
     );
}
 
export default HomeSection3;