import React from 'react';
import Img from '../../../source/carbon5.png';

const AboutSection2 = () => {
    return ( 
        <img src={Img} alt='Background mountains' className='about2-img'/>    
    );
}
 
export default AboutSection2;

