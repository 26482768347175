import React from 'react';
import Solutions1 from '../sections/solutions/Solutions1';
import Solutions2 from '../sections/solutions/Solutions2';
import Solutions3 from '../sections/solutions/Solutions3';


const Solutions = () => {
    return ( 
        <>
            <Solutions1/>
            <Solutions2/>
            <Solutions3/>
        </>
    );
}
 
export default Solutions;