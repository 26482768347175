import React from 'react';
import AboutSection1 from '../sections/about/AboutSection1';
import AboutSection2 from '../sections/about/AboutSection2';
import AboutSection3 from '../sections/about/AboutSection3';
import AboutSection4 from '../sections/about/AboutSection4';
import AboutSection5 from '../sections/about/AboutSection5';
import AboutSection6 from '../sections/about/AboutSection6';


const About = () => {
    return ( 
        <>
            <AboutSection1/>
            <AboutSection2/>
            <AboutSection3/>
            <AboutSection4/>
            <AboutSection6/>
            {/* <AboutSection5/> */}
        </>
     );
}
 
export default About;