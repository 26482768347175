import React from "react";
import {
  HashRouter as Router,
  Routes ,
  Route,
} from "react-router-dom";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Contact from "./components/pages/Contact";
import Solutions from "./components/pages/Solutions";
import Carbon from "./components/pages/Carbon";
import Nav from "./components/ui/Nav";
import Footer from "./components/ui/Footer";

const RouterPages = () => {
    return ( 
      <>
        <Router>    
          <Nav/>
          <Routes>
            <Route path="/solutions" element={ <Solutions />}/>
            <Route path="/carbon-offset" element={ <Carbon />}/>
            <Route path="/about" element={ <About />}/>
            <Route path="/contact" element={ <Contact />}/>
            <Route exact path="/" element={ <Home />}/>
          </Routes>
          <Footer/>
        </Router>
      </>
    );
}
 
export default RouterPages;