import React from 'react';
import Img from '../../../source/verraLogo.png';

const CarbonSection6 = () => {
    return ( 
        <div className='carbon6-container'>
            <img src={Img} alt='africa' className='carbon6-img'/> 
        </div>
     );
}
 
export default CarbonSection6;