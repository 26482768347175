import React from 'react';
import VerraLogo from '../../../source/verraLogo.png';
import LionsLogo from '../../../source/lionsLogo.png';
import GoldLogo from '../../../source/goldLogo.png';
const HomeSection4 = () => {
    return ( 
        <div className='home4-container'>
            <div className='home4-divider'></div>
            <h2>Our Partners</h2>
            <div className='home4-logos'>
                    <img src={VerraLogo} alt='Icons' className='home4-icons'/>
                    <img src={LionsLogo} alt='Icons' className='home4-icons'/>
                    <img src={GoldLogo} alt='Icons' className='home4-icons'/>
            </div>
        </div>
     );
}
 
export default HomeSection4;