import React from 'react';
import Img from '../../../source/sensorImg.png';

const CarbonSection4 = () => {
    return ( 
        <div className='carbon4-container'>
            <div className='carbon4-texts'>
                <h2>Offset Quality</h2>
                <p>
                    180 quantifies emissions by using active IoT temperature monitoring sensors, and bluetooth devices installed directly onto the cookstoves.                    
                    <br/>
                    <br/>
                    Data is transmited via reliable bluetooth and cellphone networks.
                    <br/>
                    <br/>
                    With scheduled audits and careful analysis, 180 produces millions of accurate, high-impact, certified carbon credits.
                </p>
                <p>
                    Earnings from valuable, Verified Carbon Units (VCUs) are invested into more cookstove installations, and localised sustainable tree farming.  Furthermore we enable our 180.earth foundation to carry out natural forest regeneration.               
                </p>
            </div>
            <img src={Img} alt='sensor'/>
        </div>
     );
}
 
export default CarbonSection4;