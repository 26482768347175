import React from 'react';
import Title1 from '../../../source/svg/textNamizimu.svg';
import Map from '../../../source/mapAbout6.png';
import Img from '../../../source/imgAbout6.png';

const AboutSection6 = () => {
    return ( 
        <div className='about6-container'>
            <img src={Title1} alt='Title'/>
            <img src={Map} alt='Title'/>
            <div className='about6-description'>
                <img src={Img} alt='Title'/>
                <p>
                    This forest reserve of 88,000 hectares is located in a little-known part of the country, sandwiched
                    between the South-Eastern end of Lake Malawi and the Mozambique border. The Mangochi–Namizimu escarpment marks the start of a large upland area east of the Rift Valley which extends into Mozambique and southern Tanzania.
                    <br/>
                    <br/>
                    It rises steeply from the Lake Malawi shores (500 m) to Msondole peak (1,800 m). Important wildlife populations are found in the forest reserve of Namizimu. Lions and elephants still live in this sparsely populated region.
                    <br/>
                    <br/>
                    Important ancient elephant migration routes from Mozambique across the border to Namuzimu
                    Forest in Malawi still exist. The main type of vegetation is woodland, with also dense riparian forest,
                    lake-shore thicket forest and small patches of montane rainforest.

                </p>

            </div>
        </div>
     );
}
 
export default AboutSection6;