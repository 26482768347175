import React from 'react';
import Arrow from '../../../source/svg/arrowBlack.svg';

const Solutions3 = () => {
    return ( 
        <div className='solutions3-container'>
            <div className='solutions3-title'>
                <h2>Driven by reliable data, our projects utilise non-invasive tech-solutions to achieve the best results.</h2>
            </div>
            <div className='solutions3-contents'>
                <div className='solutions3-content'>
                    <h3>180.works cookstoves</h3>
                    <div>
                        <img src={Arrow} alt='arrow'/>
                        <p>
                            IoT sensor technology accurately monitors cookstove usage, and is audited by independent teams.                        
                        </p>
                    </div>
                    <div>
                        <img src={Arrow} alt='arrow'/>
                        <p>
                            The status of cookstove clusters will be registered in the 180.works secure carbon credits consolidation and
                            distribution ERP platform.                        
                        </p>
                    </div>
                    
                </div>
                <div className='solutions3-content'>
                    <h3>Natural forest regeneration</h3>
                    <div>
                        <img src={Arrow} alt='arrow'/>
                        <p>
                            We monitor the regeneration of forests with geo-tagged locations, satellite imagery and smart phone surveys.
                        </p>
                    </div>
                    <div>
                        <img src={Arrow} alt='arrow'/>
                        <p>
                            Forest regeneration passports will be created to provide frequent status reports on growth and carbon captured.
                        </p>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Solutions3;