import React from 'react';
import Img from '../../../source/svg/solution1.svg';
import Img2 from '../../../source/solution1tree.png';


const Solutions1 = () => {
    return ( 
        <div className='solutions1-container'>
            <div className='solutions1-section1'>
                <img src={Img} alt='autor'/>

            </div>
            <div className='solutions1-section2'>
                <h2>The problem</h2>
                <p>
                    94% of rural households in Africa still cook on open fires. Globally, this practice contributes
                    to over 25% of carbon emissions! Illegal and unregulated deforestation, to source wood-fuel for cooking, not only devastates local ecosystems but also accelerates global climate change. It leads to extreme temperatures, reduced soil fertility and water retention, a dangerous decline in natural defences against extreme weather conditions, resulting in life-threatening droughts, floods, landslides, and devastating consequences for biodiversity, infrastructure and agriculture.
                </p>
                <h2>The need for clean cooking technologies</h2>
                <p>
                    Inefficient cooking methods are a dangerously-overlooked contributor to the
                    climate crisis. 2.4 billion people in the world still cook on open fires and inefficient
                    cookstoves, contributing to 20 % of global air pollution, and heavily contributing to
                    deforestation as firewood and charcoal are used as the main fuel source. It is vital more
                    efficient methods be implemented to benefit both humans and forests.
                </p>
                <h2>The consequences of inaction</h2>
                <p>
                    Deforestation dramatically influences the health, wellbeing, and opportunities of
                    everyone. Catastrophic conditions for farming and biodiversity are already
                    noticeable factors. People simply cannot survive without healthy, sustainable forests.
                </p>
                <img src={Img2} alt='autor'/>
            </div>

        </div>
     );
}
 
export default Solutions1;