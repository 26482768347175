import React from 'react';
import Text from '../../../source/svg/textAbout180.svg';


const AboutSection1 = () => {
    return ( 
        <div className='about1-container'>
            <img src={Text} alt='Text about 180' />
            <div className='about1-texts'>
                <p>
                    The global need to regrow more trees is unprecedented. We want to turn deforestation around 180° - hugely impacting climate change and people's lives. 180 recognizes the business model has the potential to be the biggest game-changer. Partnering with governments, investors, stakeholders, communities and donors - providing more transparency and better data analysis. 180 focuses on non-invasive technology to provide the most verifiable and auditable
                    "proof of work" and more importantly, "proof of impact".                   
                    <br/>
                    <br/>
                    Actively partnering with the Malawi Government to overcome the illegal charcoal trade, and fulfill social, economic and environmental responsibilities, 180 is expanding the success of its cookstove and reforestation projects to many more countries.
                </p>
            </div>
        </div>    
    );
}
 
export default AboutSection1;