import React from 'react';
import Picture1 from '../../../source/picture1.png';
import Picture2 from '../../../source/people.png';
import Title from '../../../source/svg/180Title.svg'
import PictureTree from '../../../source/homeTree.png'
// import {useInView } from 'react-intersection-observer';

const HomeSection5 = () => {
    
   
    return ( 
        <div className='home5-container'>
            {/* <div className='home5-contents'>
                <div className='home5-texts'>
                    <h3>The impact of <br/> deforestation</h3>
                    <p>
                        In Africa, 94% of rural households cook over an open fire. Local communities use wood from forests to meet their needs. The amount of firewood consumption leads to deforestation and has an impact on climate change.
                        <br/>
                        <br/>
                        On a local level, deforestation means that the soil loses its ability to retain water, which causes erosion. This has a negative effect on agriculture. On a global level, it decreases the number of trees able to store CO2.
                    </p>
                </div>
                <div className='home5-img' id='home5-img1'>
                    <img src={Picture1} alt='farm camp' />
                </div>
            </div>
            <div className='home5-contents'>
                <div className='home5-texts'>
                    <h3>Increase the level <br/> of our impact</h3>
                    <p>
                        Via our foundation, 180.earth, we regrow forests to increase our impact to stop climate change. 
                        We are partners of the Government of Malawi to conserve forests starting with Namizimu Forest Reserve.
                    </p>
                </div>
                <div className='home5-img' id='home5-img2'>
                    <img src={Picture2} alt='farm camp' />
                </div>
            </div> */}
            <img src={Title} alt='180 mission'/>
            <h3>
                Africa has the potential to be a leader in large scale forest conservation and premium carbon capture. 180 sees Africa pioneering its fight against climate change.
            </h3>
            <img src={PictureTree} alt='Tree description'/>
            <h2>180.earth</h2>
            <p>
                Our European-based Foundation is focused on rejuvenating wild forests.
                180.earth invests in large-scale forest regeneration and local income-generating ventures. By the end of 2040, and with the help of locally employed communities, 180.earth wants to save, regenerate, and protect 5 million hectares of wild forest
            </p>
            <h2>180.works</h2>
            <p>
                To prevent deforestation and generate certified, cookstove carbon credits, global operations are managed by 180.works.
                <br/>
                <br/>
                <span>
                    "Together, 180 entities Combat Deforestation, Reduce Carbon Emissions, and 
                    Foster Community Development."
                </span>
            </p>

        </div>
     );
}
 
export default HomeSection5;