import React from 'react';
import Logo from '../../source/logo/logo3.svg';

const Footer = () => {
    return ( 
        <footer className='footer-container'>
            <img src={Logo} alt='Logo'/>
            <p>  
                <span>180.works </span><br/>
                Argonweg 17, 3812RB Amersfoort, The Netherlands<br/>
                join@180.works <br/>
                +31-2000266
            </p>
        </footer>
     );
}
 
export default Footer;