import React from 'react';
import HomeSection1 from '../sections/home/HomeSection1';
import HomeSection2 from '../sections/home/HomeSection2';
import HomeSection3 from '../sections/home/HomeSection3';
import HomeSection4 from '../sections/home/HomeSection4';
import HomeSection5 from '../sections/home/HomeSection5';


const Home = () => {
   
    return ( 
        <>  
            <HomeSection1/>
            <HomeSection2/>
            <HomeSection3/>
            <HomeSection5/>
            <HomeSection4/>
        </>
     );
}
 
export default Home;