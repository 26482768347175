import React from 'react';
import PictureCeo from '../../../source/ceo.png';
import PictureCto from '../../../source/cto.png';
import PictureManager from '../../../source/manager.png';
// import PictureMission from '../../../source/mission.png';
// import PictureMaarten from '../../../source/maartenImg.png'
import PictureRobert from '../../../source/RobertDelhaas.png'
import PictureJelena from '../../../source/jelenaImg.png'
import Title from '../../../source/svg/textTheCore.svg';

const AboutSection4 = () => {
    return ( 
        <div className='about4-container'>
            <div className='about4-mission'>
                {/* <img src={PictureMission} alt='Mission'/> */}
            </div>
            <img src={Title} alt='The Core Team of 180' className='about4-title'/>

            <div className='about4-team'>
                <div className='about4-people'>
                    <img src={PictureCeo} alt='Ceo'/>
                    <h3>Martijn  Hekman</h3>
                    <h4>Founder & CEO</h4>
                    <p>
                        Martijn believes that climate change will be largest emergency humankind will face in the near
                        future.
                        <br/>
                        His 22 years of experience in large-scale agri-forestry programs amongst others made him
                        found 180.works. After working with Fortune 500 companies, startups and the UN, he founded and
                        invested in several successful enterprises.
                        <br/>
                        Martijn is based in Malawi and has strong relationships
                        with local communities and government.
                    </p>
                </div>
                <div className='about4-people'>
                    <img src={PictureCto} alt='Ceo' className='img-hermna'/>
                    <h3>Herman Vissia</h3>
                    <h4>CTO</h4>
                    <p>
                        Herman is a pioneer in technology. He founded Byelex, a successful data science innovation company
                        and is a lecturer on Artificial Intelligence, Blockchain and cryptocurrency. 
                        <br/>
                        <br/>
                        As our CTO he enables
                        180.works to deliver the best data to our partners.
                   </p>
                </div>
                {/* <div className='about4-people'>
                    <img src={PictureMaarten} alt='Ceo'/>
                    <h3>Maarten Drukker</h3>
                    <h4>Advisor </h4>
                    <p>
                        Doing good business, the right way. Maarten is determined to contribute to fight climate change in the best way possible, stopping deforestation.
                        <br/>
                        <br/>
                        As an entrepreneur, he has a background in energy and was a driving force of one of the largest sustainability platforms of the Netherlands. Beside this, he has broad experience broad experience as a consultant overseeing. 
                        Above all he has initiated various sustainability programs worldwide.
                    </p>
                </div> */}
                <div className='about4-people'>
                    <img src={PictureManager} alt='Ceo'/>
                    <h3>Samson Wasiri <br/> </h3>
                    <h4>Malawi Country Manager</h4>
                    <p>
                        Samson is specialized in Data Science and has extensive experience with the management and monitoring of fuel-efficient cookstove programs 
                        in Southern Africa. 
                        <br/>
                        <br/>
                        Samson oversees our cookstoves
                        program and is eager to involve local communities to benefit from our solutions.
                    </p>
                </div>
            </div>
            <div className='about4-team'>
                
             
                <div className='about4-people'>
                    <img src={PictureJelena} alt='Ceo'/>
                    <h3>Jelena Bozinovski</h3>
                    <h4> Program Coordinator </h4>
                    <p>
                        Jelena is a humanitarian and development aid professional with 13 years  experience with grant acquisition and implementation management. 
                        <br/>
                        <br/>
                        She oversees all solutions and is determined to
                        reforest Southern Africa as to reduce global warming.
                    </p>
                </div>
                <div className='about4-people'>
                    <img src={PictureRobert} alt='Ceo'/>
                    <h3>Robert Delhaas </h3>
                    <h4>Partner</h4>
                    <p>
                        Robert is Economist and Sociologist by
                        background (Tilburg / Oxford), Robert
                        Delhaas started at Philips Electronics and
                        became executive board member of
                        `Vendex/Hodon-Holding in 1991.
                        From 1999 he became senior partner of Econ Group, (re)structuring and refinancing medium to large size industries (such as NS (Dutch Railways), NOB, Belgacom, Baan) and nonprofit organizations in EU-, East European- and Middle Eastern countries.
                        (sometimes as delegated CEO, initiated by several banks or venture capital firms).
                        He is active as board member of some
                        nonprofit organizations focusing on poverty reduction, integration of refugees and micro finance with focus on Africa and South America.    
                    </p>
                </div>
            </div>
            <div className='about4-separate'></div>
            <h2>Company Structure</h2>
            <h5>
                180.earth is based in the Netherlands. Vital to have a European entity, 180.earth remain current on scientific carbon matters, financial makets, and be present for partners and investors. All our long-term forest regeneration and forest concessions are managed by 180.earth.                <br/>
                <br/>
                 180.works has early operations set up in Malawi, working effectively with the European office, and with partners and staff on the ground - intent on expanding projects abroad.            </h5>
        </div>
     );
}
 
export default AboutSection4;