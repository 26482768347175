import React from 'react';
import RouterPages from './RouterPages';
import './css/index.css';
function App() {
  return (
    <RouterPages/>
  );
}

export default App;
