import React,{useEffect} from 'react';
import {useInView } from 'react-intersection-observer';
import Title from '../../../source/svg/textVerra.svg';
import LogoVerra from '../../../source/verraLogo.png';

const CarbonSection5 = () => {
    const { ref, inView } = useInView();

    var speed = 5;
    const incEltNbr = id => {
        const elt = document.getElementById(id);
        const endNbr = parseInt(elt.innerHTML.replace(/,/g,''));
        console.log()
        incNbrRec(0, endNbr, elt);
    
    }
    const incNbrRec = (i, endNbr, elt) =>{
      if (i <= endNbr) {
        elt.innerHTML = i;
        setTimeout(function() {//Delay a bit before calling the function again.
          incNbrRec(i + 1, endNbr, elt);
        }, speed);
      }
    }
    const  incNb = () => {
        incEltNbr("n1");
        incEltNbr("n2");
        incEltNbr("n3");
        incEltNbr("n4");
        incEltNbr("n5");
        incEltNbr("n6");
        incEltNbr("n7");
        
      }
    useEffect(() => {
        
          if(inView) incNb();
    }, [inView])
    




    return ( 
        <div className='carbon5-container'>
            <div className='carbon5-title'>
              <h3>180.works current project in Malawi, Southern Africa</h3>
            </div>  
            <h2>Project Details</h2>
            <hr></hr>
            <div className='carbon5-texts carbon5-texts1'>
                <p>Project <br/><span>VCS 3073</span></p>
                <p>Location <br/><span>Malawi, Africa</span></p>
                <p>Developer <br/><span>180.Works </span></p>
                <p>Standard<br/><span>Verified Carbon <br/> Standard </span></p>
                <p>Sectoral Scope <br/><span>Energy Demand</span></p>
                <p>Vintages <br/><span>Annual</span></p>
            </div>
            <hr></hr>
            <div className='carbon5-texts carbon5-texts2' ref={ref}>
                <p > Carbon Credit Units  expected to be issued <h3><span id='n1' >14</span>,<span id='n2' >204</span>,<span id='n3' >627</span></h3><br/> Minimum Length of Project 10 Year Crediting Period</p>
                <p> Avg. Annual <br/> Reduction <br/><h3><span id='n4' >1</span>,<span id='n5' >420</span>,<span id='n6' >463</span></h3></p>
                <p> Annual Installed Cookstoves<br/><h3><span id='n7' >300</span>,<span  >000</span></h3></p>
            </div>
            <hr></hr>
            <div className='carbon5-texts carbon5-texts3'>
                <p>Methodology<br/><span>VMR0006</span></p>
                <p>Project Start Date<br/><span>January 1, 2022 </span></p>
            </div>
        </div>
     );
}
 
export default CarbonSection5;