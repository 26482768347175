import React from 'react';
import Video from '../../../source/video/video3.mp4';
import Icon1 from '../../../source/svg/co2Pink.svg';
import Icon2 from '../../../source/svg/handsPink.svg';
import Icon3 from '../../../source/svg/treePink.svg';
import Icon4 from '../../../source/svg/peoplesPink.svg';

const CarbonSection3 = () => {
    return ( 
        <div className='carbon3-container'>
            <video src={Video} loop controls />
            <div className='carbon3-icons'>
                <div>
                    <img src={Icon1} alt='Icon' />
                    <h3>Reduced Carbon Emissions</h3>
                </div>
                <div>
                    <img src={Icon2} alt='Icon' />
                    <h3>Increased Well-Being</h3>
                </div>
                <div>
                    <img src={Icon3} alt='Icon' />
                    <h3>Decreased Deforestation</h3>
                </div>
                <div>
                    <img src={Icon4} alt='Icon' />
                    <h3>Creates Job Opportunities</h3>
                </div>
            </div>
        </div>
     );
}
 
export default CarbonSection3;