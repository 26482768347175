import React from 'react';
import Text from '../../../source/svg/textFoundation.svg';
import Img from '../../../source/aboutFoundation.png';
// import IconHands from '../../../source/svg/hands.svg'
// import IconGraphic from '../../../source/svg/graphic.svg'

const AboutSection3 = () => {
    return ( 
        <div className='about3-container'>
            <div className='about3-texts'>
                <img src={Text} alt='text' className='about3-img1'/>
                <p>
                    180.earth is a foundation focuses on acquiring and rejuvenating forests that have been destroyed by
                    excessive, illegal logging. By the end of 2040 we want to save and restore 5 million hectares of forests.
                    These forest will offer refuge to wildlife, reduce local temperatures and help retain groundwater.
                    Above all, they contribute to the reduction of climate change.
                    <br/>
                    <br/>
                    To stop deforestation, 180.works will invest over the coming years in the installation of cookstoves and
                    tree farms. After the cookstove stage is complete, and sales realised, our foundation, 180.earth, will send profits back into wild forest regeneration.
                </p>
            </div>

            <img src={Img} alt='text' className='about3-img2'/>
        </div>
     );
}
 
export default AboutSection3;