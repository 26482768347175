import React from 'react';
import Img from '../../../source/carbon1.png';
import LogoVerra from '../../../source/verraLogo.png';
import Title from '../../../source/svg/carbon1Title.svg'


const CarbonSection1 = () => {
    return ( 
        <div className='carbon1-container'>
            <img src={Img} className='carbon1-img' alt='Route'/>
            <div className='carbon1-contents'>
                <img src={Title} className='carbon1-title' alt='Carbon'/>
                <p>
                    180.works enables the reduction of greenhouse gas emissions by installing efficient cookstoves in
                    rural communities. 180 cookstoves have a significant impact on the health and well-being of people and 
                    on the environment. Implementation has begun in Malawi.  
                    <br/>
                    <br/>
                    180 plans to extend to a dozen more countries in 2024.
                    <br/>
                    <br/>
                        Processes reduce and remove tons of greenhouse gases from the atmosphere. 
                        180 Carbon Emission reduction is monitored, audited, and rated for certified 180 Carbon Credits.
                </p>

                <img src={LogoVerra} className='carbon1-verra' alt='Verra Logo'/>
            </div>
        </div>
     );
}
 
export default CarbonSection1;