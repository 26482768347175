import React from 'react';
import Picture from '../../../source/carbon2.png';

const CarbonSection2 = () => {
    return ( 
        <div className='carbon2-container'>
            <img src={Picture} alt='People' />

            <div className='carbon2-texts'>
                <h3>MALAWI, AFRICA</h3>
                <h2>Efficient cookstoves</h2>
                <p>
                    180 cookstoves reduce the amount of harmful emissions compared to traditional cooking methods. Currently, most households cook on an open fire.                    <br/>
                    <br/>
                    Where most houses rely on a biomass fuel, such as wood or charcoal, more efficient cookstoves are a vital intervention - especially in rural Africa.                    <br/>
                    <br/>
                    Our sensors enable 180 data analysis teams to monitor live-data and report on stove usage.
                </p>
            </div>
        </div>
     );
}
 
export default CarbonSection2;