import React from 'react'; 
import Title from '../../../source/svg/180TitleSolutions.svg';
import Img1 from '../../../source/solution2Img1.png';
import Img2 from '../../../source/solution2Img2.png';
import Img3 from '../../../source/solution2Img3.png';

const Solutions2 = () => {
    return ( 
        <div className='solutions2-container'>
            <div className='solutions2-title'>
                <img src={Title} alt='cookstoves'/>

                <p>
                    180 strives for long-term, government-supported, joint-partnerships with empowered communities. Sustainable operations, backed-up with green tech solutions, create long-lasting relationships on a local level, as well as secure investment opportunities with dependable results for our impact investors.
                </p>
            </div>
            <div className='solutions2-contents'>
                <div>
                    <img src={Img1} alt='cookstoves'/>
                    <div className='solutions2-texts'>
                        <h2>1. Cookstoves</h2>
                        <p>
                            Trained community officers install efficient 180 cookstoves which burn 
                            <span> 67% less wood-fuel!</span>
                            <br/>
                            <br/>
                            Bluetooth sensors accurately record data, ensuring a more effective solution and an extremely reliable carbon-capture method for our partners.
                            Due to their efficiency, 180 stoves hugely reduce illegal and unrestricted deforestation!
                        </p>
                    </div>
                </div>
                <div>
                    <img src={Img2} alt='cookstoves'/>
                    <div className='solutions2-texts'>
                        <h2>2. Trees</h2>
                        <h3>180 grows and protects trees.</h3>
                        <p>
                            <span>a{')'}</span>
                            Agreements are made with authorities for numerous Wild Forests to be regenerated and protected on a nationwide scale. Land is assigned to 180 by Governments.

                        </p>
                    </div>
                </div>
                <div>
                    <img src={Img3} alt='cookstoves'/>
                    <div className='solutions2-texts'>
                        <p>
                            <span>b{')'}</span> Simultaneously, we set up Community Forests.
                            Fast-growing, sustainable, non-invasive species are cultivated in localised projects, and are the principal source of sustainable biofuel and build materials for
                            communities. These projects work in combination with 180 cookstoves, reducing the need for wild-forest destruction. These activities also maintain other local trades such as briquette production, bee keeping, livestock grazing, fruit production etc.
                            <br/>
                            <br/>
                            By utilising innovative and non-invasive technologies across both solutions, 180 delivers significant carbon reduction, reliable carbon credits, and a measurable impact on climate change.
                        </p>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Solutions2;