import React from 'react';
// import Video from '../../../source/video/video2.mp4';
import PictureBg from '../../../source/bgHome1.png'
import FireIcon from '../../../source/svg/fireWhite.svg';
import HandIcon from '../../../source/svg/handWhite.svg';
import TreeIcon from '../../../source/svg/treeWhite.svg';

const HomeSection1 = () => {
    return ( 
        <div  className='home1-container'>
            {/* <video src={Video} loop muted autoPlay/> */}
            <img src={PictureBg} className='home1-bg' alt='Tress'/>
            <div className='home1-texts'>
                <h1>
                    Turning deforestation <br/> around 180°

                </h1>
                <p>180 implement three solutions</p>
                <div className='home1-icons'>
                    <div>
                        <img src={FireIcon} alt='icon'/>
                        <p>Installing <br/> efficient <br/> cookstoves</p>
                    </div>
                    <div>
                        <img src={TreeIcon} alt='icon'/>
                        <p>Managed by <br/>empowered-communities,<br/>create sustainable tree farms</p>
                    </div>
                    <div>
                        <img src={HandIcon} alt='icon'/>
                        <p>Regenerate <br/> Depleted <br/>Forests</p>
                    </div>

                   
                </div>
            </div>
        </div>
     );
}
 
export default HomeSection1;