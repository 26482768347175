import React from 'react';

import CarbonSection1 from '../sections/carbon/CarbonSection1';
import CarbonSection2 from '../sections/carbon/CarbonSection2';
import CarbonSection3 from '../sections/carbon/CarbonSection3';
import CarbonSection4 from '../sections/carbon/CarbonSection4';
import CarbonSection5 from '../sections/carbon/CarbonSection5';
import CarbonSection6 from '../sections/carbon/CarbonSection6';

const Carbon = () => {
    return ( 
        <>
            <CarbonSection1/>
            <CarbonSection2/>
            <CarbonSection3/> 
            <CarbonSection4/>
            <CarbonSection5/>
            <CarbonSection6/>
        </>
     );
}
 
export default Carbon;