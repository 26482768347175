import React from 'react';
// import ParticlesComponent from '../ui/ParticlesComponent';
import IconName from '../../source/svg/user.svg';
import IconMail from '../../source/svg/mail.svg';
import IconMessage from '../../source/svg/message.svg';
import IconSend from '../../source/svg/send.svg';
const Contact = () => {
    return ( 
        <div className='contact-container'>  
        {/* <ParticlesComponent/> */}
            <h2>Contact us</h2>
            <form  name='contact' method='POST' data-netlify="true" className='form-container'>
            <input type="hidden" name="form-name" value="contact"/>

                <div className='contact-input contact-name'>
                    <label>
                        <img src={IconName} alt='icon name'/>
                        Name
                    </label>
                    <input type='text' id='name' name='name' required='required'/>
                </div>
                <div className='contact-input contact-mail'>
                    <label>
                        <img src={IconMail} alt='icon name' />
                        Email
                    </label>
                    <input type='e-mail'  name='email' required='required'/>
                </div>
                <div className='contact-input contact-message'>
                    <label>
                        <img src={IconMessage} alt='icon name'/>

                        Message
                    </label>
                    <textarea rows={5} name='menssage' />
                </div>
                <button type='submit'>
                    <p>SEND</p>
                    <img src={IconSend} alt='icon name'/>
                    
                </button>

            </form>
        </div>
     );
}
 
export default Contact;