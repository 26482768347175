import React from 'react';
import Logo from '../../../source/logo/logo3.svg';
// import {useInView } from 'react-intersection-observer';
// import ParticlesComponent from '../../ui/ParticlesComponent';
// import FireIcon from '../../../source/svg/fire.svg';
// import TreeIcon from '../../../source/svg/tree.svg';
// import HandIcon from '../../../source/svg/hand.svg';
// import TechnologyIcon from '../../../source/svg/technology.svg';

const HomeSection2 = () => {
   
    // const { ref, inView } = useInView();
    
    // useEffect(() => {
    //     // const icon1 = document.getElementById('home2-icon1');
    //     // const icon2 = document.getElementById('home2-icon2');
    //     // const icon3 = document.getElementById('home2-icon3');
    //     // const icon4 = document.getElementById('home2-icon4');
    //     // if (inView) {
    //     //     icon1.style.animation= 'scaleUp 1s';
    //     //     icon2.style.animation= 'scaleUp 2s';
    //     //     icon3.style.animation= 'scaleUp 3s';
    //     //     icon4.style.animation= 'scaleUp 4s';
    //     // }else{
    //     //     icon1.style.animation= 'none';
    //     //     icon2.style.animation= 'none';
    //     //     icon3.style.animation= 'none';
    //     //     icon4.style.animation= 'none';
    //     }
    // }, [inView])
    
    
   
    return ( 
        <div className='home2-container'>
            {/* <ParticlesComponent/> */}
            <div>
                <h2>We are</h2>
                <img src={Logo} alt='Logo 180' className='home2-logo' />
            </div>
            <p>
                180 is a progressive Dutch company focussed on slowing
                deforestation while significantly reducing carbon emissions.<br/>
                We use green technology to create viable solutions
                for communities, placing empowered people at the
                heart of our long-term approach.
            </p>

            {/* <div className='home2-icons' ref={ref}>
                <div className='home2-icon' id='home2-icon1'>
                    <img src={FireIcon} alt='icon'/>
                    <p>Fuel Efficient Cookstove</p>
                </div>
                <div className='home2-icon' id='home2-icon2' >
                    <img src={TreeIcon} alt='icon'/>
                    <p>Forest Conservation</p>
                </div>
                <div className='home2-icon'id='home2-icon3' >
                    <img src={HandIcon} alt='icon'/>
                    <p>Carbon Sequestration</p>
                </div>
                <div className='home2-icon' id='home2-icon4'>
                    <img src={TechnologyIcon} alt='icon'/>
                    <p>Green Technology Solutions</p>
                </div>

            </div> */}

        </div>
     );
}
 
export default HomeSection2;